import React from "react"
import SEO from "../components/seo"
import FormContacts from "../components/forms/formContacts"

const ContactPage = () => (
  <main>
    <SEO
      title="Contattaci"
      description="Scrivici se hai curiosità di noi."
    />
    <FormContacts
      title="Contattaci"
    />
  </main>

);

export default ContactPage;
