import React, {useState} from 'react';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import 'whatwg-fetch';
import ButtonSubmit from "../buttonSubmit";
import ButtonLink from "../buttonLink";

import "./forms.scss";

const FormContacts = (props) => {

  // =============================== //
  // main hooks for react-hook-form: //
  // more specific documentation here: https://seifi.org/reactjs/build-a-contact-form-in-gatsby-part-2-react-hook-form.html
  // register: for required fields
  // handleSubmit: manage submit function
  // errors: manage frontend errors
  // setError: manage server errors after catch
  // reset: reset the form values after a successful submit
  // formState.isSubmitting: disable selected fields while the form is being submitted (useful to disable the submit button)
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting }
    } = useForm({
      mode: "onSubmit"
    });
  // =============================== //

  // manage thank you message on submission
  const [submitted, setSubmitted] = useState(false);

  // submit function
  const onSubmit = data => {

    const formData = new FormData();
    for(const name in data) {
      formData.append(name, data[name]);
    }

    // on any non-2xx status, define a custom response handler
    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
            // console.log("checkStatus response: ", response);
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
            // console.log("checkStatus error: ", error);
        }
    }

    function parseJSON(response) {
        return response.json()
    }

    fetch(`${process.env.GATSBY_FORM_API_BASEURL}/contact-form`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: formData,
        headers: {
            //'Content-type': 'multipart/form-data; charset=UTF-8',
            'Accept': 'application/json',
        },
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
        setSubmitted(true);
        reset();
        // console.log('Success:', data);
    })
    .catch((error) => {
        setError(
          "submit",
          "submitError",
          `${error.message}`
        );
        console.error('Error:', error);
    });
  };

  const showSubmitError = msg =>
  <p className="server-error">
    Oops! C'è stato un errore! <br />
    {msg} <br />
    Riprova
  </p>;

  return (
    <div className="header-spacer">
      <div className="simple-title-content__wrapper">
        <h1 className="glow" dangerouslySetInnerHTML={{__html: props.title}} />
        {submitted ?
          <div className="thank-you__wrapper">
            <h2 className="title">Grazie!</h2>
            <p>I tuoi dati sono stati inviati con successo.</p>
            <ButtonLink
              onClick={() => setSubmitted(false)}
              label="Indietro"
            />
          </div>
        :
          <form className="form__wrapper" method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="input__wrapper">
              <label htmlFor="firstname">Nome</label>
              <input
                className={`bordered ${errors.firstname && 'error'}`}
                type="text"
                id="firstname"
                name="firstname"
                ref={register({
                  required: "Il nome è obbligatorio",
                  minLength: {
                    value: 2,
                    message: "La lunghezza minima del nome è di 2 caratteri"
                  },
                  maxLength: {
                    value: 100,
                    message: "La lunghezza massima del nome è di 100 caratteri"
                  }
                })}
                disabled={isSubmitting}
              />
            </div>
            {errors.firstname && <p className="error__wrapper">{errors.firstname.message}</p>}
            <div className="input__wrapper">
              <label htmlFor="lastname">Cognome</label>
              <input
                className={`bordered ${errors.lastname && 'error'}`}
                type="text"
                id="lastname"
                name="lastname"
                ref={register({
                  required: "Il cognome è obbligatorio",
                  minLength: {
                    value: 2,
                    message: "La lunghezza minima del cognome è di 2 caratteri"
                  },
                  maxLength: {
                    value: 100,
                    message: "La lunghezza massima del cognome è di 100 caratteri"
                  }
                })}
                disabled={isSubmitting}
              />
            </div>
            {errors.lastname && <p className="error__wrapper">{errors.lastname.message}</p>}
            <div className="input__wrapper">
              <label htmlFor="email">Email</label>
              <input
                className={`bordered ${errors.email && 'error'}`}
                type="email"
                id="email"
                name="email"
                ref={register({
                  required: "L'email è obbligatoria",
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "L'indirizzo email non è valido"
                  }
                })}
                disabled={isSubmitting}
              />
            </div>
            {errors.email && <p className="error__wrapper">{errors.email.message}</p>}
            <div className="input__wrapper">
              <label htmlFor="message">Scrivici qualcosa</label>
              <textarea
                className={`bordered ${errors.message && 'error'}`}
                name="message"
                id="message"
                rows="5"
                ref={register({
                  required: "Il messaggio è obbligatorio",
                  minLength: {
                    value: 2,
                    message: "La lunghezza minima del messaggio è di 2 caratteri"
                  },
                  maxLength: {
                    value: 500,
                    message: "La lunghezza massima del messaggio è di 500 caratteri"
                  }
                })}
                disabled={isSubmitting}
              >
              </textarea>
            </div>
            {errors.message && <p className="error__wrapper">{errors.message.message}</p>}
            <div className="legal__wrapper">
              <div className="privacy-accept">
                <input
                  id="privacy"
                  name="privacy"
                  type="checkbox"
                  value="1"
                  ref={register({
                    required: "L'accettazione della privacy è obbligatoria"
                  })}
                  disabled={isSubmitting}
                />
                <label htmlFor="privacy">
                    Ho letto e accetto l'informativa sulla <a className="link" href="/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
                </label>
                {errors.privacy && <p className="error__wrapper">{errors.privacy.message}</p>}
              </div>
              <div className="submit__wrapper">
                <ButtonSubmit
                  disabled={isSubmitting}
                  label="Invia"
                />
              </div>
            </div>
            {errors && errors.submit && showSubmitError(errors.submit.message)}
          </form>

        }
      </div>
    </div>
  );
}

export default FormContacts
